
import Vue from 'vue'

const locales = {keys(){return []}}
global['________'] = true;
delete global['________'];
global.__uniConfig = {"easycom":{"^u-(.*)":"uview-ui/components/u-$1/u-$1.vue","^unicloud-db$":"@dcloudio/uni-cli-shared/components/unicloud-db.vue","^uniad$":"@dcloudio/uni-cli-shared/components/uniad.vue","^ad-rewarded-video$":"@dcloudio/uni-cli-shared/components/ad-rewarded-video.vue","^ad-fullscreen-video$":"@dcloudio/uni-cli-shared/components/ad-fullscreen-video.vue","^ad-interstitial$":"@dcloudio/uni-cli-shared/components/ad-interstitial.vue","^ad-interactive$":"@dcloudio/uni-cli-shared/components/ad-interactive.vue","^page-meta$":"@dcloudio/uni-cli-shared/components/page-meta.vue","^navigation-bar$":"@dcloudio/uni-cli-shared/components/navigation-bar.vue","^uni-match-media$":"@dcloudio/uni-cli-shared/components/uni-match-media.vue"},"optimization":{"subPackages":true},"globalStyle":{"navigationBarTextStyle":"white","navigationBarTitleText":"亿竞","navigationBarBackgroundColor":"#0C1922"}};
global.__uniConfig.compilerVersion = '3.6.14';
global.__uniConfig.darkmode = false;
global.__uniConfig.themeConfig = {};
global.__uniConfig.uniPlatform = 'h5';
global.__uniConfig.appId = '';
global.__uniConfig.appName = '';
global.__uniConfig.appVersion = '1.0.0';
global.__uniConfig.appVersionCode = '100';
global.__uniConfig.router = {"mode":"history","base":"/"};
global.__uniConfig.publicPath = "/";
global.__uniConfig['async'] = {"loading":"AsyncLoading","error":"AsyncError","delay":200,"timeout":60000};
global.__uniConfig.debug = false;
global.__uniConfig.networkTimeout = {"request":60000,"connectSocket":60000,"uploadFile":60000,"downloadFile":60000};
global.__uniConfig.sdkConfigs = {};
global.__uniConfig.qqMapKey = undefined;
global.__uniConfig.googleMapKey = undefined;
global.__uniConfig.aMapKey = undefined;
global.__uniConfig.aMapSecurityJsCode = undefined;
global.__uniConfig.aMapServiceHost = undefined;
global.__uniConfig.locale = "";
global.__uniConfig.fallbackLocale = undefined;
global.__uniConfig.locales = locales.keys().reduce((res,key)=>{const locale=key.replace(/\.\/(uni-app.)?(.*).json/,'$2');const messages = locales(key);Object.assign(res[locale]||(res[locale]={}),messages.common||messages);return res},{});
global.__uniConfig.nvue = {"flex-direction":"column"}
global.__uniConfig.__webpack_chunk_load__ = __webpack_chunk_load__
Vue.component('pages-index-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/index/index"+'.vue')), 'pages-index-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-team-modal-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/team-modal/index"+'.vue')), 'pages-team-modal-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-offline-competition-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/offline-competition/index"+'.vue')), 'pages-offline-competition-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-dynamic-details-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/dynamic-details/index"+'.vue')), 'pages-dynamic-details-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-delivery-rule-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/delivery-rule/index"+'.vue')), 'pages-delivery-rule-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-login-index', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/login/index"+'.vue')), 'pages-login-index'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-user-agreement', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/user-agreement"+'.vue')), 'pages-web-view-pages-user-agreement'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-appoint-fight-link', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/appoint-fight-link"+'.vue')), 'pages-web-view-pages-appoint-fight-link'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-party-detail-link', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/party-detail-link"+'.vue')), 'pages-web-view-pages-party-detail-link'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-registration-invitation', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/registration-invitation"+'.vue')), 'pages-web-view-pages-registration-invitation'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-page-home', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/page-home"+'.vue')), 'pages-web-view-pages-page-home'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-team-rules', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/team-rules"+'.vue')), 'pages-web-view-pages-team-rules'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-article-details', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/article-details"+'.vue')), 'pages-web-view-pages-article-details'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-king-getCampID', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/king-getCampID"+'.vue')), 'pages-web-view-pages-king-getCampID'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
Vue.component('pages-web-view-pages-water-game', resolve=>{
const component = {
  component:require.ensure([], () => resolve(require("D:/front/yj-sports-h5/src/pages/web-view-pages/water-game"+'.vue')), 'pages-web-view-pages-water-game'),
  delay:__uniConfig['async'].delay,
  timeout: __uniConfig['async'].timeout
}
if(__uniConfig['async']['loading']){
  component.loading={
    name:'SystemAsyncLoading',
    render(createElement){
      return createElement(__uniConfig['async']['loading'])
    }
  }
}
if(__uniConfig['async']['error']){
  component.error={
    name:'SystemAsyncError',
    render(createElement){
      return createElement(__uniConfig['async']['error'])
    }
  }
}
return component
})
global.__uniRoutes=[
{
path: '/',
alias:'/pages/index/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          isQuit:true,
isEntry:true,

          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"亿竞H5页面导航"})
      },
      [
        createElement('pages-index-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
id:1,
  name:'pages-index-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/index/index',
isQuit:true,
isEntry:true,
  windowTop:44
}
},
{
path: '/pages/team-modal/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"战队模式","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-team-modal-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-team-modal-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/team-modal/index',
  windowTop:0
}
},
{
path: '/pages/offline-competition/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"线下赛","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-offline-competition-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-offline-competition-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/offline-competition/index',
  windowTop:0
}
},
{
path: '/pages/dynamic-details/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"动态详情","navigationStyle":"custom"})
      },
      [
        createElement('pages-dynamic-details-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-dynamic-details-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/dynamic-details/index',
  windowTop:0
}
},
{
path: '/pages/delivery-rule/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"发放规则","navigationStyle":"custom"})
      },
      [
        createElement('pages-delivery-rule-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-delivery-rule-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/delivery-rule/index',
  windowTop:0
}
},
{
path: '/pages/login/index',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"登录","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-login-index', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-login-index',
  isNVue:false,maxWidth:0,
  pagePath:'pages/login/index',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/user-agreement',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"亿竞电竞","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-user-agreement', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-user-agreement',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/user-agreement',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/appoint-fight-link',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"约战详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-appoint-fight-link', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-appoint-fight-link',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/appoint-fight-link',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/party-detail-link',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"派对详情","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-party-detail-link', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-party-detail-link',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/party-detail-link',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/registration-invitation',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"亿竞电竞","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-registration-invitation', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-registration-invitation',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/registration-invitation',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/page-home',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-page-home', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-page-home',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/page-home',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/team-rules',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-team-rules', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-team-rules',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/team-rules',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/article-details',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-article-details', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-article-details',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/article-details',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/king-getCampID',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"","navigationStyle":"custom","titleNView":false})
      },
      [
        createElement('pages-web-view-pages-king-getCampID', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-king-getCampID',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/king-getCampID',
  windowTop:0
}
},
{
path: '/pages/web-view-pages/water-game',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props: Object.assign({
          
          
          
        },__uniConfig.globalStyle,{"navigationBarTitleText":"活动详情","navigationStyle":"custom"})
      },
      [
        createElement('pages-web-view-pages-water-game', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'pages-web-view-pages-water-game',
  isNVue:false,maxWidth:0,
  pagePath:'pages/web-view-pages/water-game',
  windowTop:0
}
},
{
path: '/choose-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-choose-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'choose-location',
  pagePath:'/choose-location'
}
}
    ,
{
path: '/open-location',
component: {
  render (createElement) {
    return createElement(
      'Page',
      {
        props:{
          navigationStyle:'custom'
        }
      },
      [
        createElement('system-open-location', {
          slot: 'page'
        })
      ]
    )
  }
},
meta:{
  name:'open-location',
  pagePath:'/open-location'
}
}
    ]
global.UniApp && new global.UniApp();
